import React, { useState, useEffect } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db, auth } from "../../FirebaseConfig";
import { useNavigate } from "react-router-dom";

function RegisterDog({ isAuth }) {
  const [name, setName] = useState("");
  const [breed, setBreed] = useState("");

  const dogsCollectionRef = collection(db, "pets.dogs");
  const navigate = useNavigate();

  const createDog = async () => {
    await addDoc(dogsCollectionRef, {
      name,
      breed,
      author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
    });
    navigate("/");
  };

  // TODO use secure routes
  useEffect(() => {
    if (!isAuth) {
      navigate("/account/login");
    }
  }, []);

  return (
    <div className="createPostPage">
      <div className="cpContainer">
        <h1>Register a new dog</h1>
        <div className="inputGp">
          <label> Name:</label>
          <input
            placeholder="Name..."
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        <div className="inputGp">
          <label> Breed:</label>
          <input
            placeholder="Breed..."
            onChange={(event) => {
              setBreed(event.target.value);
            }}
          />
        </div>
        <button onClick={createDog}>Register dog</button>
      </div>
    </div>
  );
}

export default RegisterDog;
