import { auth } from "../../FirebaseConfig";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Logout({ setIsAuth }) {
  const navigate = useNavigate();

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      navigate("/account/login");
    });
  };

  signUserOut();

  return null;
}

export default Logout;
