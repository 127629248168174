import React, { useEffect, useState } from "react";

import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import { auth, db } from "../../FirebaseConfig";

function Dogs({ isAuth }) {
  const [dogs, setDogs] = useState([]);
  const dogsCollectionRef = collection(db, "pets.dogs");

  const deleteDog = async (id) => {
    const dogDoc = doc(db, "pets.dogs", id);
    await deleteDoc(dogDoc);
  };

  useEffect(() => {
    const getDogs = async () => {
      const data = await getDocs(dogsCollectionRef);
      setDogs(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getDogs();
  }, [deleteDog]);

  return (
    <div className="dogsList">
      {dogs.map((dog) => {
        return (
          <div className="post">
            <div className="postHeader">
              <div className="title">
                <h1> {dog.name}</h1>
              </div>
              <div className="deletePost">
                {isAuth && dog.author.id === auth.currentUser.uid && (
                  <button
                    onClick={() => {
                      deleteDog(dog.id);
                    }}
                  >
                    {" "}
                    &#128465;
                  </button>
                )}
              </div>
            </div>
            <div className="postTextContainer"> {dog.breed} </div>
            <h3>@{dog.author.name}</h3>
          </div>
        );
      })}
    </div>
  );
}

export default Dogs;
