import React from "react";
import Dogs from "../dog/Dogs";

function Home({ isAuth }) {
  return (
    <div className="homePage">
      <h1>Homepage</h1>
      {isAuth ? <Dogs isAuth={isAuth} /> : null}
    </div>
  );
}

export default Home;
