import "./App.css";

import { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./modules//home/Home";
import Login from "./modules/account/Login";
import Logout from "./modules/account/Logout";
import RegisterDog from "./modules/dog/RegisterDog";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  return (
    <Router>
      <nav>
        <Link to="/">Home</Link>
        {!isAuth ? (
          <Link to="/account/login">Login</Link>
        ) : (
          <>
            <Link to="/dog/register">Register Dog</Link>
            <Link to="/account/logout">Logout</Link>
          </>
        )}
      </nav>
      <Routes>
        <Route path="/" element={<Home isAuth={isAuth} />} />
        <Route
          path="/account/login"
          element={<Login setIsAuth={setIsAuth} />}
        />
        <Route
          path="/account/logout"
          element={<Logout setIsAuth={setIsAuth} />}
        />
        <Route path="/dog/register" element={<RegisterDog isAuth={isAuth} />} />
      </Routes>
    </Router>
  );
}

export default App;
